import { useState} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import Error from '../components/Error';
import FoodConceptLogo from '../assets/images/FoodConcepts_Logo-1 1.png';

const SignIn = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const msalConfig = {
        auth: {
            clientId: process.env.REACT_APP_CLIENT_ID,
            authority: process.env.REACT_APP_AUTHORITY,
            redirectUri: process.env.REACT_APP_REDIRECT_URI,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
    };

    const handleLogin = () => {
        if (error) return;

        const msalInstance = new PublicClientApplication(msalConfig);
        const loginRequest = {
            scopes: [process.env.REACT_APP_SCOPE],
        };

        msalInstance.initialize()
            .then(() => {
                const currentAccounts = msalInstance.getAllAccounts();

                if (!currentAccounts || currentAccounts.length < 1) {
                    msalInstance.loginPopup(loginRequest)
                        .then((data) => {
                            console.log(data);
                            localStorage.setItem("account", JSON.stringify(data.account));
                            localStorage.setItem("accessToken", data.accessToken);
                            localStorage.setItem("idToken", data.account.idToken);
                            localStorage.setItem("name", data.account.name);
                            localStorage.setItem("username", data.account.username);
                            localStorage.setItem('isLoggedin', true);
                            localStorage.setItem('msalConfig', JSON.stringify(msalConfig));
                            toast.success('Login successful');
                            navigate('/home');
                        })
                        .catch(error => {
                            console.error("Login Error: " + error);
                        });
                } else if (currentAccounts.length > 1) {
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    const username = currentAccounts[0].username;
                    localStorage.setItem("username", username);
                    navigate('/home');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="h-screen flex flex-col justify-center items-center bg-cover bg-center bg-blue-900" >
        <div className="text-center flex flex-col justify-center items-center bg-white py-8 max-w-sm rounded-lg px-8">
          <img src={FoodConceptLogo} alt="Logo" className="mt-4 w-4/5" />
          <h2 className="text-lg font-semibold text-gray-700">Goods Receipt Management Portal</h2>
          <p className="text-sm font-normal text-gray-500 mt-2">Sign in with your Microsoft credentials and continue to the application</p>
          
                         
            <button
              className="bg-blue-900 hover:bg-blue-900 text-white font-semibold mt-8 py-3 w-full rounded-lg"
              type="button"
              onClick={handleLogin}
            >
              Sign In
            </button>
  
       
        </div>
        {error && <Error message={errorMessage} show={showModal} handleShow={() => setShowModal(false)} />}
      </div>
    );
};

export default SignIn;
