import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import PageTitle from '../components/PageTitle';
import { useNavigate,useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Swal from "sweetalert2";
import axios from 'axios';
import Loader from '../components/Loader';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { NumericFormat } from 'react-number-format';
import { DateRangePicker } from 'rsuite';
import Select from 'react-select';
import { convertDateFormat } from './GrnPage';
import makeAnimated from 'react-select/animated';
import { ReactComponent as DownloadIcon } from '../assets/images/downloadIcon.svg';
import Error from '../components/Error';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SessionExpired from '../components/sessionExpired';
import Pagination from '../components/Pagination';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };


  return (
    <div className='flex'>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const History = () => {
  const [error, setError] = useState(null);
  const token=localStorage.getItem('accessToken')
  const [filteredCreatedList, setFilteredCreatedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState('');
  const [count, setCount] = useState('');
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState([])
  const [loading, setLoading] = useState(false);
  const location=useLocation()
  const animatedComponents = makeAnimated();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 useEffect(() => {
  const params = new URLSearchParams(location.search);
  const pageParam = params.get('page');
  const pageSizeParam = params.get('page_size');

  const newPage = pageParam ? Number(pageParam) : 1;
  const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

  setPage(newPage);
  setSize(newPageSize);

  getGRN(newPage, newPageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location.search]);

 const getGRN = async (page,size) => {

  setLoading(true);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/egrn/v1/grns?page=${page}&size=${size}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    console.log(response, "I am the res");
    setHistoryData(response.data.data.results);
    setCount(response.data.data.count)
    const totalPages=Math.ceil(response.data.data.count/size)
    setTotal(totalPages)
    Swal.close();
  } catch (error) {
    console.log(error, "PO fetching error.");
        const errorMessage = error?.response?.data?.detail||'Error Fetching PO Items'
          ? error?.response?.data?.detail
          : 'Error Fetching PO';

          if (error?.response?.status === 401) {
            // Call handleLogout in case of 401 error
            // handleLogout(msalConfig, userName, navigate);
            setsessionExpired(true)
          }
        else{setError(errorMessage);
      }
  } finally {
    setLoading(false); // Hide loading indicator
  }
};

const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
const filteredData = historyData?.filter((item) => {
  const  { grn_number, purchase_order, total_value_received}= item;
  const searchValue = searchQuery.toLowerCase();
  
  const matchesExternalDocumentId = grn_number.toString().toLowerCase().includes(searchValue);
  
  const matchesPoId =purchase_order.po_id.toString().toLowerCase().includes(searchValue);
  const matchesVALUE =total_value_received.toString().toLowerCase().includes(searchValue);
  return matchesExternalDocumentId || matchesPoId || matchesVALUE;
});

const [statusFilter, setStatusFilter] = useState('All Delivery Status');
const colourStyles = {
  control: (styles,state) => ({ ...styles,borderColor :"#eaeaea",margiTop:'-1px',
  backgroundColor: state.isDisabled ? 'grey' : 'white',
}),
}
// Define a function to filter the table data based on the selected status
const filterTableData = () => {
  const formattedStartDate = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
  const formattedEndDate = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;

  return historyData.filter((item) => {
    // Destructure necessary fields from the item
    const { grn_number, purchase_order, total_value_received, created } = item;

    // Search filter (case-insensitive match across multiple fields)
    const searchValue = searchQuery.toLowerCase();
    const matchesSearch = searchQuery
      ? [
          grn_number?.toString().toLowerCase(),
          purchase_order?.po_id?.toString().toLowerCase(),
          total_value_received?.toString().toLowerCase(),
        ].some((field) => field?.includes(searchValue))
      : true;

    // Date range filter
    const matchesDate = startDate && endDate
      ? new Date(created).getTime() >= formattedStartDate &&
        new Date(created).getTime() <= formattedEndDate
      : true;

    // Status filter
    const matchesStatus =
      statusFilter === 'All Delivery Status'
        ? true
        : statusFilter === 'Partially Delivered'
        ? purchase_order?.delivery_status_text === 'Partially Delivered'
        : statusFilter === 'Completely Delivered'
        ? purchase_order?.delivery_status_text === 'Completely Delivered'
        : true;

    // Combine all filters
    return matchesSearch && matchesDate && matchesStatus;
  });
};

// Call filterTableData whenever a filter changes
useEffect(() => {
  const filtered = filterTableData();
  setFilteredCreatedList(filtered);
}, [searchQuery, statusFilter, startDate, endDate, historyData]);

// Function to filter filteredCreatedList based on selected date

const handleDateRangeChange = (range) => {
  if (range) {
    const [start, end] = range;
    setStartDate(start);
    setEndDate(end);
  } else {
    setStartDate(null);
    setEndDate(null);
  }
};

const handleStatusFilterChange = (selectedOption) => setStatusFilter(selectedOption.value)


   
 
const convertToCSV = (dataList) => {
  const headers = [
    'Store ByD Code', 
    'Store Name', 
    'GRN Number', 
    'PO Number', 
    'Vendor Name', 
    'Date Created',
    'Product',
    'Unit Price',
    'Quantity Delivered',
    'Delivery Status',
    'Amount'
  ];

  let rows = [];

  dataList.forEach((data) => {
    data.grn_line_items.forEach((lineItem) => {
      rows.push([
        data.stores[0]?.byd_cost_center_code || '-',
        data.stores[0]?.icg_warehouse_name.replace(/,/g, '') || '-',
        data.grn_number,
        data.purchase_order.po_id || '-',
        data.purchase_order.Supplier?.SupplierName[0]?.FormattedName || '-',
        new Date(data.created).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }),
        lineItem.purchase_order_line_item?.product_name.replace(/,/g, '') || '-',
        lineItem.purchase_order_line_item?.unit_price || '0',
        lineItem.quantity_received || '0',
        lineItem.purchase_order_line_item?.delivery_status_text || '-',
        lineItem.net_value_received || '0'
      ]);
    });
  });

  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += headers.join(',') + '\r\n';

  rows.forEach(row => {
    csvContent += row.join(',') + '\r\n';
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'GRN_history.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



const [sessionExpired,setsessionExpired]=useState(false)
console.log(filteredData,'')
  const handleShowGRN = (data) =>{
    localStorage.setItem('PostResponse', JSON.stringify(data));
    navigate('/grn', { state: { history: true } });
  }
console.log(filteredCreatedList,'group')
  return (
    <div>
        {loading && <Loader/>}
          {error &&  <Error message={error}/>}
 {!sessionExpired ?
 <>
    <Header />
   <div className="flex main-body">
 <div className="sidebar hidden md:block">
   <Sidebar />
 </div>
 <div className="content md:w-[86%] w-full md:ml-[14%] p-3">
          <PageTitle pagetitle='GRN History' className='mt-3' />
        
          <section class="bg-gray-50  sm:p-5">
    <div class="mx-auto w-full ">
      
        <div class="bg-white flex flex-col relative  sm:rounded-lg overflow-hidden">
            <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                <div class="w-full md:w-1/2">
                    <form class="flex items-center">
                        <label for="simple-search" class="sr-only">Search</label>
                        <div class="relative w-full">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 " fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:outline-none block w-full pl-10 p-2  " placeholder='Search by GRN number, PO number and total amount'
value={searchQuery}
onChange={handleSearchChange}required=""/>
                        </div>
                    </form>
                </div>
                <div className="flex gap-3">
                <DateRangePicker
      format='dd-MM-yyyy'
      placeholder="Filter by Date Range"
      style={{ height: 50 }}
      ranges={[]}
     className="w-1/2 md:w-48"
      placement='bottomEnd'
      preventOverflow
    
      onChange={handleDateRangeChange}
      onClean={() => handleDateRangeChange(null)}
    />

<Select
  closeMenuOnSelect={true}
  components={animatedComponents}
  styles={colourStyles}
  options={[
    { value: 'All Delivery Status', label: 'All Delivery Status' },
    { value: 'Partially Delivered', label: 'Partially Delivered' },
    { value: 'Completely Delivered', label: 'Completely Delivered' },
  ]}
  value={{ value: statusFilter, label: statusFilter }}
  onChange={handleStatusFilterChange}
   className="w-1/2 md:w-48"
/>
                </div>
            </div>
            <button className="flex self-end w-[90%]  mx-4 md:w-48 text-white rounded-md mb-4 bg-[#067203] items-center justify-center font-medium text-base px-4 gap-3 py-2"  onClick={() => convertToCSV(filteredCreatedList)}>
                        <DownloadIcon/>
                        <span>Export as csv</span>
                    </button>
            <div class="overflow-x-auto md:w-full w-[90%] mx-auto">
            <table className="min-w-[1024px] w-full">
  <thead className='rounded-lg text-left bg-blue-800 text-sm font-normal'>
    <tr className='font-poppins text-19 font-semibold leading-29'>
      <th scope="col" className="px-4 py-3 text-white">
        SN 
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        Date Created
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        PO Number
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        GRN Number
      </th>
      
      <th scope="col" className="px-4 py-3 text-white">
        Vendor 
      </th>
      <th scope="col" className="px-4 py-3 text-white">
        Delivery Status
      </th>
      <th scope="col" className="text-right pr-8 md:pr-28 py-3 text-white ">
        Amount
      </th>
      
      <th scope='col' className='pr-3 py-3 text-white '>
        Action
      </th>
    </tr>
  </thead>
  <tbody>
  {Array.isArray(filteredCreatedList) && filteredCreatedList.length > 0 ? (
    // [...filteredCreatedList].reverse()
    filteredCreatedList.map((row, index) => (
        <tr className="w-full border-b py-2 text-sm last-of-type:border-none" key={index}>
          <td className="px-4 py-3">
            {page === 1 ? page + index : ((size * page) - size + 1) + index}
          </td>
          <td className="px-4 py-3">
            {convertDateFormat(row.created, 'yyyy-MM-dd', 'dd-MMM-yyyy')}
          </td>
          <td className="px-4 py-3">
            {row.purchase_order.po_id || '-'}
          </td>
          <td className="px-4 py-3">{row.grn_number}</td>
          
          <td className="px-4 py-3">
            {row.purchase_order.Supplier.SupplierName[0].FormattedName || '-'}
          </td>
          <td className="px-4 py-3">
            <span className={`rounded-3xl px-3 py-1 ${
              row.purchase_order.delivery_status_text === 'Completely Delivered'
                ? 'bg-green-100 text-green-700'
                : row.purchase_order.delivery_status_text === 'Not Delivered'
                ? 'bg-red-100 text-red-600'
                : 'bg-gray-100 text-gray-600'
            }`}>
              {row.purchase_order.delivery_status_text === 'Partially Delivered' ? 'Partial' : 'Complete'}
            </span>
          </td>
          <td className="text-right pr-8 md:pr-28 py-3">
            <span className="font-semibold">₦</span>
            <NumericFormat
              value={row.total_value_received}
              displayType="text"
              thousandSeparator={true}
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
            />
          </td>
          
          <td className="flex py-3 pr-3  font-semibold text-center">
            <button
              onClick={() => handleShowGRN(row)}
              className="border border-blue-700 bg-blue-700 items-center flex gap-1 rows-center justify-center py-1 hover:bg-blue-800 text-white px-2 rounded"
            >
              <RemoveRedEyeIcon sx={{ fontSize: 16 }} />
              <span className="text-xs">View</span>
            </button>
          </td>
        </tr>
      ))
  ) : (
    <tr className="w-full text-center">
      <td colSpan="8" className="py-4 text-red-600 font-semibold">
        No record found!
      </td>
    </tr>
  )}
</tbody>


</table>
<Pagination
totalPages=
{total}
entriesPerPage=
{size}
totalEntries=
{count}/>

            </div>

            
        </div>
    </div>
    </section>
    </div>
   </div>
 </>
 :
 <SessionExpired/>
 }
   </div> 
  );
};

export default History;
