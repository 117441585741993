import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import Loader from "../components/Loader";
import Error from "../components/Error";
import { DateRangePicker } from "rsuite";
import Pagination from "../components/Pagination";
import Papa from "papaparse";
import { Download,MoreHorizontal, X  } from "lucide-react";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
const GRNFilter = () => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const location=useLocation()
  const [filters, setFilters] = useState({
    po_id: "",
    vendor_internal_id: "",
    delivery_stores: "",
    start_date: null,
    end_date: null,
    delivery_status_code: "",
    invoice_status_code: "",
  });

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleDateRangeChange = (value) => {
    setFilters({
      ...filters,
      start_date: value ? dayjs(value[0]).format("DD/MM/YYYY") : "",
      end_date: value ? dayjs(value[1]).format("DD/MM/YYYY") : "",
    });
  };


  const handleShowError = () => {
    setShowError(false);
  };

  const fetchData = async (page,size) => {
    setLoading(true);
    setShowError(false);

    const token = localStorage.getItem("accessToken");

    if (!token) {
      setErrorMessage("No access token found. Please log in.");
      setShowError(true);
      setLoading(false);
      return;
    }

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "" && value !== null)
      );

      const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/egrn/v1/filter-grns`, {
        params: { ...filteredParams, page, size },
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(response.data.results || []);
      setCount(response.data.count)
    const totalPages=Math.ceil(response.data.count/size)
    setTotalPages(totalPages)
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage(error.response?.data?.message || "An error occurred while fetching data.");
      setShowError(true);
    }

    setLoading(false);
  };

 useEffect(() => {
  const params = new URLSearchParams(location.search);
  const pageParam = params.get('page');
  const pageSizeParam = params.get('page_size');

  const newPage = pageParam ? Number(pageParam) : 1;
  const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

  setPage(newPage);
  setSize(newPageSize);

  fetchData(newPage, newPageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location.search]);
const exportToCSV = () => {
  if (data.length === 0) {
    alert("No data available to export.");
    return;
  }

  const csvData = data.map((item) => ({
    "PO Number": item?.purchase_order?.po_id || "",
    "GRN Number": item.grn_number || "",
    "Vendor Name":
      item?.purchase_order?.Supplier?.SupplierName[0]?.FormattedName || "",
    "Vendor Code":item?.purchase_order?.Supplier?.PartyID ,
    
    "Date Created": item.created || "",
    "Store Name": item?.stores[0]?.store_name || "",
    "Store ByD Code": item?.stores[0]?.byd_cost_center_code || "",
    "Invoice Status": item.invoice_status_text || "",
    "Total Value": item.total_value_received || "",
  }));

  const csv = Papa.unparse(csvData);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "GRN_History.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

  return (
    <div>
      {loading && <Loader />}
      <Error message={errorMessage} show={showError} handleShow={handleShowError} />

      <Header />
      <div className="flex main-body">
        <div className="sidebar hidden md:block">
          <Sidebar />
        </div>
        <div className="content md:w-[86%] w-full md:ml-[14%] p-3">
        <div className="rounded-xl bg-white">
      <div className="text-base font-semibold mb-4 bg-gray-50 py-3 px-6 text-blue-800 flex justify-between items-center">
        <div>GRN History</div>
        <button
          onClick={exportToCSV}
          className="flex items-center cursor-pointer gap-1 rounded-md bg-green-700 py-3 px-4 text-white text-sm"
        >
          <Download className="h-5" /> Export as CSV
        </button>
      </div>

      <div className="my-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-6">
        <DateRangePicker
          format="dd/MM/yyyy"
          placeholder="Select Date Range"
          onChange={handleDateRangeChange}
          className="w-full border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-300"
        />
        {Object.keys(filters).map((key) =>
          !["start_date", "end_date"].includes(key) ? (
            <input
              key={key}
              type="text"
              name={key}
              value={filters[key]}
              onChange={handleChange}
              placeholder={key
                .replace(/_/g, " ")
                .replace(/\b\w/, (char) => char.toUpperCase())}
              className="border-gray-300 py-2 md:py-1 px-4 border rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-300"
            />
          ) : null
        )}
        <button
          onClick={fetchData}
          className="p-2 bg-blue-800 text-white rounded-md hover:bg-blue-900"
        >
          Search
        </button>
      </div>

      {data.length > 0 && (
        <table className="mt-2 w-full bg-white rounded-t-lg">
          <thead>
            <tr className="bg-slate-50 text-gray-700 border-b border-gray-200 font-medium">
              <td className="p-3">SN</td>
              <td className="p-3">PO Number</td>
              <td className="p-3">GRN Number</td>
              <td className="p-3">Vendor ID</td>
              <td className="p-3">Date Created</td>
              <td className="p-3">Store Code</td>
              <td className="p-3">Invoice Status</td>
              <td className="text-right pr-8">Total Value</td>
              <td className="p-3 flex items-center justify-center">Actions</td>
            </tr>
          </thead>
          <tbody>
            {data.map((item,index) => (
              <tr key={item.grn_number} className="border-b border-gray-200">
                <td className="p-3"> {page === 1 ? page + index : ((size * page) - size + 1) + index}</td> 
                <td className="p-3">{item?.purchase_order?.po_id}</td>
                <td className="p-3">{item.grn_number}</td>
                <td className="p-3">
                  {
                    item?.purchase_order?.Supplier?.PartyID
                  }
                </td>
                <td className="p-3">{item.created}</td>
                <td className="p-3">{item?.stores?.[0]?.byd_cost_center_code}</td>
                <td className="flex items-center   py-2">
                  <div  className={`flex  py-1 items-center justify-center  rounded-md w-4/5 ${item?.invoice_status_text==='Finished'?'bg-green-50 text-green-700 border-green-500':'bg-gray-50 text-gray-700 border-gray-500'}`}>{item?.invoice_status_text}</div>
                  </td>
                <td className="text-right pr-8">
                ₦<NumericFormat
        value={item?.total_value_received}
        displayType="text"
        thousandSeparator
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
                </td>
                <td className="flex items-center justify-center p-3">
                  <button
                    onClick={() => setSelectedRow(item)}
                    className="text-gray-700 hover:text-blue-500"
                  >
                    <MoreHorizontal className="h-5 w-5 border border-gray-400 rounded-full"/>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
 <Pagination totalPages={totalPages} entriesPerPage={size} totalEntries=
{count}/>
      {selectedRow && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 right-1 flex justify-end z-50"
          onClick={() => setSelectedRow(null)}
        >
          <div
            className="bg-white h-screen overflow-auto  w-full md:w-[30%] shadow-lg  overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center border-b p-4">
              <h2 className="text-lg font-medium">Full Details</h2>
              <button
                onClick={() => setSelectedRow(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={20} className="border rounded-full"/>
              </button>
            </div>
            <div className="px-4">
      {[
        { label: "PO Number", value: selectedRow?.purchase_order?.po_id },
        { label: "GRN Number", value: selectedRow?.grn_number },
        { 
          label: "Vendor Name", 
          value: selectedRow?.purchase_order?.Supplier?.SupplierName?.[0]?.FormattedName 
        },
        { 
          label: "Vendor Code", 
          value: selectedRow?.purchase_order?.Supplier?.PartyID 
        },
        { label: "Store Name", value: selectedRow?.stores?.[0]?.store_name },
        { label: "Store Code", value: selectedRow?.stores?.[0]?.byd_cost_center_code },
      ].map((item, index) => (
        <div key={index} className="flex mt-4 text-sm justify-between">
          <div className="w-[50%] text-gray-900">{item.label}</div>
          <div className="text-gray-500 w-[50%] text-right">{item.value || "N/A"}</div>
        </div>
      ))}

      {/* Invoice Status */}
      <div className="flex mt-4 text-sm justify-between">
        <div className="w-[50%] text-gray-900">Invoice Status</div>
        <div className={`text-gray-500 w-[50%] justify-end flex items-center gap-1 ${selectedRow?.invoice_status_text ? "text-green-600" : "text-gray-700"}`}>
          <div className={`h-1 w-1 rounded-full ${selectedRow?.invoice_status_text ? "bg-green-600" : "bg-gray-400"}`}></div>
          {selectedRow?.invoice_status_text }
        </div>
      </div>

      {/* Total Value */}
      <div className="flex mt-4 text-sm justify-between">
        <div className="w-[50%] text-gray-900">Total Value</div>
        <div className="text-gray-500 w-[50%] text-right">
          {selectedRow?.total_value_received ? (
            <NumericFormat
              value={selectedRow.total_value_received}
              displayType="text"
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
              prefix="₦"
            />
          ) : (
            "N/A"
          )}
        </div>
      </div>
    </div>

          </div>
        </div>
      )}
    </div>
        </div>
      </div>
    </div>
  );
};

export default GRNFilter;
